.about-title-text {
  font-family: Aileron;
  font-weight: 700;
  color: #414141;
  margin-top: -5px;
  font-size: calc(20px + 2vmin);
}

.about-page-instagram {
  text-decoration: none;
  color: #00e676;
  vertical-align: top;
}

.about-body-text {
  font-family: Aileron-Light;
  font-weight: 100;
  color: #484848;
  font-size: calc(10px + 2vmin);
}

.body-container-mobile {
  text-align: justify;
  margin-left: calc(1px + 7vw);
  margin-right: calc(1px + 7vw);
}

.body-container {
  text-align: justify;
  margin-left: calc(200px + 10vmin);
  margin-right: calc(200px + 10vmin);
}

.about-title-container {
  text-align: center;
  margin-left: 7px;
  margin-right: 7px;
}

.contact-form {
  text-align: center;
  display: flex;
  margin: 0 auto;
  flex-flow: row wrap;
  margin-left: calc(20px + 25vw);
  margin-right: calc(20px + 25vw);
}

.contact-form-mobile {
  text-align: center;
  display: flex;
  margin: 0 auto;
  flex-flow: row wrap;
  margin-left: calc(10px + 5vw);
  margin-right: calc(10px + 5vw);
}

.input-contact-form {
  border-width: 2px;
  border-bottom-width: 0px;
  border-color: #00e676;
  border-style: solid;
  width: 47.7%;
  font-family: Aileron;
  padding-left: 10px;
  font-size: calc(10px + 1vmin);
  height: calc(20px + 5vmin);
}

.input-contact-form:focus, .comment-box-style:focus {
  outline-color: #2e7d32;
}

.input-contact-form-mobile {
  border-width: 2px;
  border-bottom-width: 0px;
  border-color: #00e676;
  border-style: solid;
  width: 97.9%;
  font-family: Aileron;
  padding-left: 10px;
  font-size: calc(10px + 1vmin);
  height: calc(20px + 5vmin);
  -webkit-appearance: none;
  border-radius: 0;
}

.submit-button {
  background-color: #00e676;
  justify-self: right;
  color: white;
  font-family: Aileron;
  font-size: calc(10px + 1vmin);
  padding: 10px;
  border: none;
  width: calc(200px + 5vmin)
}

.submit-button-mobile {
  background-color: #00e676;
  justify-self: right;
  color: white;
  font-family: Aileron;
  font-size: calc(10px + 1vmin);
  padding: 10px;
  border: none;
  width: 100%;
  border-radius: 0;
  height: calc(20px + 5vmin);
  -webkit-appearance: none;
}

.submit-button:hover, .submit-button:active {
  background-color: #2e7d32;
}

.comment-box-style {
  border-width: 2px;
  border-color: #00e676;
  border-style: solid;
  padding-left: 10px;
  padding-top: 10px;
  width: 97.5%;
  font-weight: 200;
  font-family: Aileron-Light;
  font-size: calc(5px + 2vmin);
  height: calc(50px + 5vmin);
  -webkit-border-radius: 0;
  border-radius: 0;
}