.tabBarContainer {
  position:fixed;
  left: 0px;
  bottom:0px;
  height:70px;
  width:100%;
  display: flex;
  background: #FAFAFA;
  border-style: solid;
  border-width: 1px 0px 0px;
  border-color: #BDBDBD;
  justify-content: space-between;
}

.barIcon {
  width: 50px;
  height: 50px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  vertical-align: top;
}

.padding-container {
  height: 50px;
  width: 100%;
  background-color: white;
}