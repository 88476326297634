.header-style {
  display: flex;
  flex-grow: row wrap;
  margin-bottom: 10vmin;
}

.header-logo {
  height: calc(30px + 4vmin);
  width: calc(30px + 4vmin);
  margin-left: 10vw;
  margin-right: calc(5px + 3vmin);
  margin-top: calc(5px + 1vmin);
}

.header-logo-mobile {
  height: calc(30px + 4vmin);
  width: calc(30px + 4vmin);
  margin-left: calc(1px + 3vw);
  margin-right: calc(5px + 3vmin);
  margin-top: calc(5px + 1vmin);
}

.header-title {
  font-family: Aileron;
  font-weight: 900;
  color: #434a5e;
  font-size: calc(10px + 2vmin);
}

.header-container {
  width: 100%;
  height: 70px;
  background: white;
  margin-left: calc(40px + 5vmin);
}

.header-container-mobile {
  width: 100%;
  height: 70px;
  background: white;
}

.header-link-design {
  display: flex;
  flex-flow: row wrap;
  text-decoration: none;
  margin-top: 10vh;
}

.header-link-design-mobile {
  display: flex;
  flex-flow: row wrap;
  text-decoration: none;
}

.header-link-element {
  text-decoration: none;
  color: #434a5e;
}

.header-link-element:hover, .header-link-element:active {
  color: #a9b4d4;
}

.header-link-element-selected {
  color: #a9b4d4;
  text-decoration: none;
}

.page-links {
  flex-flow: row wrap;
  justify-content: space-between;
  display: flex;
  margin-right: calc(50px + 30vmin);
  width: 30vw;
  margin-top: 11.5vh;
}