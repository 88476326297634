.App-preview-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto;
}

.App-preview-container-mobile {
  flex-direction: column;
  align-items: center;
}

.social-media-logo {
  height: calc(20px + 3vmin);
  width: calc(40px + 15vmin);
  margin-top: calc(7px + 1.5vmin);
}

.bottom-page-links {
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: center;
  flex-flow: row;
  justify-content: space-around;
  display: flex;
  width: calc(150px + 15vw);
}

.bottom-page-link-container {
  width: 100%;
}

.about-page-link {
  text-decoration: none;
  color: #434a5e;
  vertical-align: top;
}

.about-page-link:hover, .about-page-link:active {
  text-decoration: none;
  color: #a9b4d4;
  vertical-align: top;
}

.about-page-text {
  font-weight: 700;
  font-family: Aileron-Semi;
  font-size: calc(10px + 1.5vmin);
}
