.login-form-container {
  margin: 0 auto;
  text-align: center;
}

.login-component {
  margin-bottom: calc(10px + 5vh);
}

.login-page-title-text {
  font-family: Aileron;
  font-weight: 900;
  font-size: calc(17px + 2vmin);
}

.login-page-title-text-mobile {
  font-family: Aileron;
  font-weight: 900;
  font-size: calc(20px + 3vmin);
  text-align: left;
  margin-left: calc(20px + 3vw);
}

.green-underline-box {
  background-color: #00e676;
  height: 0.5vh;
  width: calc(15px + 10vmin);
  margin: 0 auto;
  margin-top: -3vh;
  margin-bottom: 5vh;
}

.green-underline-box-mobile {
  background-color: #00e676;
  height: 0.5vh;
  width: calc(57px + 10vmin);
  margin-left: calc(20px + 3vw);
  margin-top: -3vh;
  margin-bottom: 5vh;
}

.login-page-icon {
  width: calc(15px + 2vmin);
  height: calc(15px + 2vmin);
  margin-right: calc(5px + 1.5vw);
  vertical-align: middle;
}

.login-form-input {
  -webkit-appearance: none;
  border-radius: 0;
  border-width: 0 0px 1px 0px;
  border-bottom-color: #BDBDBD;
  resize: none;
  vertical-align: middle;
  width: calc(230px + 10vmin);
  font-family: Aileron-Light;
  font-size: calc(16px + 1vmin);
}

.login-form-input:focus {
  border-bottom-color: #00e676;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.login-page-submit {
  background-color: #00e676;
  color: white;
  font-family: Aileron;
  font-size: calc(10px + 2vmin);
  width: calc(260px + 16vmin);
  height: calc(20px + 5vmin);
  margin-bottom: calc(5px + 1vmin);
  border: none;
  outline: none;
}

.login-to-beta {
  font-family: Aileron-Light;
  color: #BDBDBD;
}

.login-to-beta:hover {
  color: #00e676;
}

.error-text-login {
  color: red;
  text-align: center;
  font-size: calc(5px + 1vmin);
  font-family: Aileron-Semi;
}